(function () {
  const mouseHandler = function (evt: MouseEvent) {
    const e = evt.target as HTMLElement;

    const list = e.parentNode.querySelectorAll('li.opened');
    for (let i = 0; i < list.length; i++)
      list[i].classList.remove('opened');

    if (evt.type === 'mouseenter' || evt.type === 'click') {

      let menu = evt.type === 'mouseenter' ? e.lastElementChild : (e.parentNode as HTMLElement).nextElementSibling;
      if (menu && (menu.classList.contains('sub-menu'))) {
        const parent = menu.parentNode as HTMLElement;
        const state = parent.classList.contains('opened');
        if (state) {
          if (evt.type === 'click') {
            const list = menu.querySelectorAll('li.opened');
            parent.classList.remove('opened');
            for (let i = 0; i < list.length; i++)
              list[i].classList.remove('opened');
          }
        } else {
          const list = document.querySelectorAll('li.opened');
          for (let i = 0; i < list.length; i++)
            list[i].classList.remove('opened');
          while (menu.classList.contains('sub-menu')) {
            parent.classList.add('opened');
            menu = parent.parentNode as HTMLElement;
          }
        }
      }
    }
  };

  const toggleHandler = function (e) {
    e.preventDefault();
    const menu = document.getElementById('menu');
    menu.addEventListener('click', mouseHandler);
    menu.classList.toggle('opened');
  };

  const initMenu = function () {
    const menu = document.getElementById('menu');
    if (menu) {
      const toggle = document.getElementById('toggleMenu');
      toggle.removeEventListener('click', toggleHandler);
      menu.removeEventListener('click', mouseHandler);
      document.querySelectorAll('#menu > li').forEach(function (li) {
        li.removeEventListener('mouseenter', mouseHandler);
      });
      menu.removeEventListener('mouseleave', mouseHandler);

      const mobileMenu = getComputedStyle(toggle).display !== 'none';
      if (mobileMenu) {
        document.querySelectorAll('#menu > li > .sub-menu').forEach(c => c.previousElementSibling.removeAttribute('href'));
        toggle.addEventListener('click', toggleHandler);
      }
      else {
        document.querySelectorAll('#menu > li').forEach(function (li) {
          li.addEventListener('mouseenter', mouseHandler);
        });
        menu.addEventListener('mouseleave', mouseHandler);
      }
    }
  };

  window.addEventListener('load', initMenu);
  window.addEventListener('resize', initMenu);
})();